import React from 'react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import InstagramIcon from '@material-ui/icons/Instagram'
import Button from '../../components/Button'

const useStyles = (props) =>
    makeStyles((theme) => ({
        artDirected: {
            [theme.breakpoints.down('sm')]: {
                width: '73px',
                height: '30px',
            },
        },
        img: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        copyright: {
            order: 2,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                order: 3,
            },
        },
        icons: {
            order: 3,
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                order: 2,
            },
        },
        link: {
            fontSize: '1.25rem',
            color: theme.palette.text.white,
            lineHeight: '0.9',
            '&:hover': {
                color: theme.palette.secondary.main,
                cursor: 'pointer',
            },
            '&:active': {
                color: theme.palette.secondary.main,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        pageLink: {
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                textAlign: 'center',
            },
        },
    }))

const Footer = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles(props)()

    const link = (name) => (name === 'index' ? '/' : `/${name}`)

    const dataLinks = {
        // 0: {
        //     available: true,
        //     contentful_id: '3PunlTsT2l07jGVj4Z4Tky',
        //     id: '3PunlTsT2l07jGVj4Z4Tky',
        //     name: 'index',
        //     menuText: 'Home',
        //     order: null,
        //     title: 'Melio Education - Online Learning with Oxford and Cambridge tutors',
        // },
        1: {
            available: true,
            contentful_id: '31cmAwGY8QOBMT8RrL5fNE',
            id: '6ac33fbd-d6bf-5552-b13f-955f770f6f6a',
            menuText: 'T&C',
            name: 'terms',
            title: 'Terms and Conditions',
        },
        // 2: {
        //     available: true,
        //     contentful_id: '6k6O6XhbuonvPxl35k3uiT',
        //     id: 'd4ab1246-7116-5dcd-b6a5-98a0bba916f8',
        //     menuText: 'Course dates',
        //     name: 'upcoming-course-dates',
        //     title: 'Upcoming course dates',
        // },
        2: {
            available: true,
            contentful_id: '6LHqKMMkS036uS7uD5dP6h',
            id: 'bfe2db4a-0c06-5cab-8dad-2f1e7a6a5c25',
            menuText: 'Tutors',
            name: 'tutors',
            order: 2,
            title: 'Tutors All',
        },
        3: {
            available: true,
            contentful_id: '5TWoDi16jOOi6YVDERecDS',
            id: '5TWoDi16jOOi6YVDERecDS',
            menuText: 'Help',
            name: 'help',
            title: 'Help',
        },
        4: {
            available: true,
            contentful_id: '1l91xSgOT3zh0ef7QMDoN2',
            id: '3c78eed9-ce72-5f0e-b9ac-34ff7c98f35f',
            menuText: 'Careers',
            name: 'careers',
            title: 'Careers',
        },
        5: {
            available: true,
            contentful_id: '4uAgLmTjZ3G65y2VgN6ATB',
            id: 'b013070f-5f12-59b8-9172-212c0b250302',
            menuText: 'About',
            name: 'about-us',
            order: 1,
            title: 'About Us',
        },
        // 6: {
        //     available: true,
        //     contentful_id: '78C9rMCKFhQWMw4rV6pBvo',
        //     id: '78C9rMCKFhQWMw4rV6pBvo',
        //     menuText: 'Courses',
        //     name: 'courses',
        //     title: 'Courses',
        // },
    }

    const images = withArtDirection(getImage(data.logo), [
        {
            media: '(max-width: 600px)',
            image: getImage(data.logoMobile),
        },
    ])

    return (
        <Box bgcolor="background.black" py={5}>
            <Container maxWidth="lg">
                <Grid container spacing={4} direction="row" alignItems="center">
                    <Grid item className={classes.img} xs={12} md={4}>
                        <GatsbyImage
                            image={images}
                            className={classes.artDirected}
                            alt={data.logo.title}
                        />
                    </Grid>
                    <Grid item container xs={12} md={8}>
                        {Object.keys([dataLinks][0]).map((option) => {
                            return (
                                <Grid item xs={2} className={classes.pageLink}>
                                    <Typography
                                        className={classes.link}
                                        onClick={() =>
                                            props.navigate(
                                                link(dataLinks[option].name)
                                            )
                                        }
                                    >
                                        {dataLinks[option].menuText}
                                    </Typography>
                                </Grid>
                            )
                        })}
                        <Grid item xs={2} className={classes.pageLink}>
                            <Typography
                                className={classes.link}
                                onClick={() =>
                                    props.navigate(link('contact/contact-us'))
                                }
                            >
                                Contact
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.copyright} xs={12} md={6}>
                        <Typography className={classes.link}>
                            © 2021 Melio
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        xs={12}
                        md={6}
                        className={classes.icons}
                    >
                        <Grid item xs={1}>
                            <Button
                                target="_blank"
                                startIcon={<FacebookIcon color="primary" />}
                                href={data.facebookLink.facebookLink}
                            >
                                <Typography></Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                target="_blank"
                                startIcon={<TwitterIcon color="primary" />}
                                href={data.twitterLink.twitterLink}
                            >
                                <Typography></Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                target="_blank"
                                startIcon={<LinkedInIcon color="primary" />}
                                href={data.linkedInLink.linkedInLink}
                            >
                                <Typography></Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                target="_blank"
                                startIcon={<InstagramIcon color="primary" />}
                                href={data.instagramLink.instagramLink}
                            >
                                <Typography></Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Footer
