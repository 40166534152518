import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { AppBar, Toolbar, createStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import Button from '../../components/Button'
import ButtonSelector from '../../components/ButtonSelector'
import { navigate, Link } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Divider from '@material-ui/core/Divider'
import CloseIcon from '@material-ui/icons/Close'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            backgroundColor: theme.palette.background.default,
            boxShadow: '0px 0px',
        },
        mobileDropdown: {
            backgroundColor: theme.palette.background.default,
        },
        fullList: {
            width: 'auto',
        },
        image: {
            width: '200px',
            [theme.breakpoints.down('sm')]: {
                width: '97px',
                height: '40px',
                marginTop: '25px',
            },
        },
        link: {
            height: '100%',
            fontSize: '1.19rem',
            textDecoration: 'none',
            color: theme.palette.secondary.black,
            '&:hover': {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.secondary.main,
                cursor: 'pointer',
            },
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.text.black,
            },
        },
        navPosition: {
            marginRight: '40px',
            [theme.breakpoints.down('sm')]: {
                marginRight: '0px',
            },
        },
        active: {
            color: theme.palette.secondary.main,
        },
        listItem: {
            backgroundColor: theme.palette.background.white,
        },
        listItemfff: {
            position: 'absolute',
            width: '150px',
            height: '42px',
            right: '20px',
            top: '16px',
            paddingLeft: '0px',
        },
        listItemText: {
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: ' bold',
            fontSize: '14px',
            lineHeight: '18px',
        },
        gridItem: {
            textAlign: 'center',
            paddingRight: '10px',
            paddingLeft: '10px',
        },
        buttonPrimary: {
            minWidth: '8.375rem !important',
            height: '3.625rem',
            borderRadius: '1rem',
            color: theme.palette.text.white,
            fontSize: '1.19rem',
            lineHeight: '22px',
            margin: '10px 0px 0px 0px',
            background:
                'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
            '&:hover': {
                background:
                    'linear-gradient(138.25deg, rgb(255 155 83) 7%, rgb(222 91 9) 95.28%)',
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: '7.438rem !important',
                margin: '10px 0px 0px -21px',
                height: '2.625rem',
                fontSize: '0.875rem',
            },
        },
        buttonSecondary: {
            width: '100%',
            height: '3.625rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.19rem',
            background:
                'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
            '&:hover': {
                background:
                    'linear-gradient(152.51deg, rgba(20, 255, 215, 0.5) -64.25%, rgba(117, 204, 189, 0.5))',
            },
        },
        buttonContainer: {
            textAlign: 'right',
        },
        dropdownText: {
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: ' 18px',
            paddingLeft: '16px',
            marginTop: '16px',
        },
    })
)
//Navigation item links go to homepage if you click home while being on another page

const link = (id, name, pathPrefix) =>
    name === 'index' ? '/' : pathPrefix ? `/${pathPrefix}/${name}` : `/${name}`
const applyLink = (name) => (name === 'index' ? '/' : `/${name}`)
//Navigation mobile or tablet
const TemporaryDrawer = (props) => {
    const { data, filteredOptions, menuData } = props
    const classes = useStyles()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    const toggleDrawer = (side, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }
        setState({ ...state, [side]: open })
    }
    const FullList = (props) => {
        const [anchorEl, setAnchorEl] = React.useState({})
        const [openDropDown, setOpenDropDown] = React.useState()
        const { options, menuData, classes } = props
        const roots = findRoots(menuData, options)
        const handleClick = (id, item) => (event) => {
            event.persist()
            if (id === openDropDown) {
                setOpenDropDown('')
            } else {
                setOpenDropDown(id)
            }
            if (!anchorEl[id]) {
                event.stopPropagation()
            }
            event.preventDefault()
            setAnchorEl((existing) => ({
                ...existing,
                [id]: event.currentTarget,
            }))
        }
        const handleClose = (id) => () => {
            setAnchorEl(() => ({
                [id]: null,
            }))
        }
        const recursiveMapper =
            (arr, level = 0) =>
            (item, index) => {
                const childrenOfItem = findMyChildren(arr, item.node.id)
                const menuId = `menu-${level}-${item.node.menuText}`

                if (childrenOfItem.length > 0) {
                    if (level === 0) {
                        return (
                            <div>
                                <Divider />
                                <Accordion
                                    style={{ boxShadow: 'none' }}
                                    disableGutters={true}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel2d-content"
                                        id="panel1d-header"
                                    >
                                        <Typography
                                            style={{
                                                fontWeight: 600,
                                            }}
                                            className={classes.listItemText}
                                        >
                                            {item.node.menuText}
                                        </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <div
                                            id={menuId}
                                            style={{ width: '100%' }}
                                        >
                                            {childrenOfItem.map(
                                                recursiveMapper(arr, level)
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    }
                    return (
                        <Menu
                            id={menuId}
                            key={item.node.id}
                            anchorEl={anchorEl[menuId]}
                            keepMounted
                            open={Boolean(anchorEl[menuId])}
                            onClose={handleClose(menuId)}
                            TransitionComponent={Fade}
                        >
                            {childrenOfItem.map(
                                recursiveMapper(arr, level + 1)
                            )}
                        </Menu>
                    )
                }

                //in case there is no dropdown
                if (level === 0) {
                    return (
                        <div className={classes.fullList} role="presentation">
                            <Divider />
                            <List disablePadding className={classes.appBar}>
                                <Link
                                    className={classes.link}
                                    to={link(
                                        menuId,
                                        item.node.name,
                                        item.node.pathPrefix
                                    )}
                                >
                                    <ListItem
                                        style={{ minHeight: '64px' }}
                                        className={classes.listItem}
                                    >
                                        <ListItemText align="left">
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                        className={
                                                            classes.listItemText
                                                        }
                                                    >
                                                        {item.node.menuText}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ListItem>
                                </Link>
                            </List>
                        </div>
                    )
                }
                return (
                    <List className={classes.mobileDropdown}>
                        <Link
                            className={classes.link}
                            to={link(
                                menuId,
                                item.node.name,
                                item.node.pathPrefix
                            )}
                        >
                            <ListItem>
                                <ListItemText align="left">
                                    <Typography
                                        className={classes.listItemText}
                                    >
                                        {item.node.menuText}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </Link>
                    </List>
                )
            }

        const hasPhoneNumber = Boolean(data.phoneNumber)
        return (
            <React.Fragment>
                <Container maxWidth="lg">
                    <Grid
                        container
                        xs={12}
                        style={{ paddingTop: '20px', paddingBottom: '20px' }}
                    >
                        <Grid item xs={12}>
                            <CloseIcon
                                fontSize="medium"
                                style={{ margin: '10px 10px 30px 10px' }}
                                onClick={toggleDrawer('top', false)}
                            />
                        </Grid>
                        {hasPhoneNumber && (
                            <>
                                {' '}
                                <Grid
                                    icon
                                    xs={1}
                                    align="left"
                                    style={{
                                        paddingLeft: '13px',
                                        marginRight: '18px',
                                    }}
                                >
                                    <Icon style={{ fontSize: 0 }}>
                                        <StaticImage
                                            src="../../assets/Vector.svg"
                                            alt="phone"
                                        />
                                    </Icon>
                                </Grid>
                                <Grid item xs={10} align="left">
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontWeight: 600,
                                            color: 'rgba(15, 5, 24, 0.6)',
                                            marginLeft: '10px',
                                        }}
                                    >
                                        {data.phoneNumber}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>

                    {roots.map(recursiveMapper(menuData))}
                </Container>
            </React.Fragment>
        )
    }
    return (
        <div>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer('top', true)}
            >
                <MenuIcon
                    fontSize="large"
                    style={{
                        backgroundColor: 'rgba(241, 87, 30, 0.1)',
                        color: '#F45F00',
                        borderRadius: '14px',
                        width: '40px',
                        height: '100%',
                        padding: '8px',
                    }}
                />
            </IconButton>
            <Drawer
                style={{ backgroundColor: '#ffffffb5' }}
                anchor="top"
                open={state.top}
                onClose={toggleDrawer('top', false)}
            >
                {/*FullList('top') - Only links No button*/}
                <FullList
                    options={filteredOptions}
                    menuData={menuData}
                    classes={classes}
                />
                {/*Apply Button Mobile*/}
                <ListItem
                    className={
                        data.button[0].buttonColor === 'Orange'
                            ? classes.listItemfff
                            : classes.listItem
                    }
                >
                    {/**/}
                    <ListItemText align="center" variant="body2">
                        {data &&
                            data.button &&
                            data.button.map((button) => {
                                const buttonColor = (color) => {
                                    if (color === 'Orange') {
                                        return classes.buttonPrimary
                                    }
                                    return classes.buttonSecondary
                                }
                                const theButtonColor = buttonColor(
                                    button.buttonColor
                                )
                                const hasTagClass = button.tagClass
                                    ? button.tagClass
                                    : ''
                                return (
                                    <Grid item xs={12}>
                                        <ButtonSelector
                                            className={`${theButtonColor} ${hasTagClass}`}
                                            data={button}
                                        >
                                            {button.label}
                                        </ButtonSelector>
                                    </Grid>
                                )
                            })}
                    </ListItemText>
                </ListItem>
            </Drawer>
        </div>
    )
}
const dataOptions = {
    0: {
        available: true,
        contentful_id: '480jXQp2ZpVYOS0AunDTEE',
        id: '480jXQp2ZpVYOS0AunDTEE',
        menuText: 'University preparation',
        name: 'university-preparation',
        order: 2,
        title: 'University Preparation (Root menu)',
    },
    1: {
        available: true,
        contentful_id: '4tbQ50DrlyOpEqHTYWZO8V',
        id: '4tbQ50DrlyOpEqHTYWZO8V',
        menuText: 'Where to study',
        name: 'where-to-study',
        order: 3,
        title: 'Where to study (Root menu)',
    },
    2: {
        available: true,
        contentful_id: '5LWO5JBWJI13L6RFPkiuHI',
        id: '5LWO5JBWJI13L6RFPkiuHI',
        menuText: 'Melio for schools',
        name: 'melio for schools',
        order: 4,
        title: 'schools',
    },
    // 3: {
    //     available: true,
    //     contentful_id: '3pk6v4cfcMxChmlAmqsRvr',
    //     id: '3pk6v4cfcMxChmlAmqsRvr',
    //     menuText: 'About',
    //     name: 'about',
    //     order: 5,
    //     title: 'About Melio',
    // },
    4: {
        available: true,
        contentful_id: '2mxEinTMBYE4OtKlBgLp8u',
        id: '2mxEinTMBYE4OtKlBgLp8u',
        menuText: 'Get advice',
        name: 'blog',
        order: 5,
        title: 'Blog',
    },
}
// const filteredOptions = data.options.filter(available);
// const filteredOptions = dataOptions;
const findRoots = (arr, options) => {
    // const availableIds = [dataOptions].map(option => option.index.id)
    const availableIds = Object.keys([dataOptions][0]).map(
        (option) => dataOptions[option].id
    )
    // console.log('availableIDs', availableIds)
    // console.log('Object.keys([dataOptions])', Object.keys([dataOptions]))
    // console.log('[dataOptions]', [dataOptions])
    const unsortedList = arr.filter(
        (item) =>
            item.node.parentPage === null &&
            item.node.available === true &&
            availableIds.includes(item.node.id)
    )
    return unsortedList.sort((a, b) => (a.node.order > b.node.order ? 1 : -1))
}
const findMyChildren = (arr, id) => {
    const unsortedList = arr.filter(
        (item) =>
            item.node.parentPage &&
            item.node.parentPage.id === id &&
            item.node.available === true
    )
    return unsortedList.sort((a, b) => (a.node.order > b.node.order ? 1 : -1))
}
//Dropdown check and Desktop
const MenuRenderer = (props) => {
    const [anchorEl, setAnchorEl] = React.useState({})
    const { options, menuData, classes } = props
    const roots = findRoots(menuData, options)
    const handleClick = (id) => (event) => {
        event.persist()
        if (!anchorEl[id]) {
            event.stopPropagation()
        }
        event.preventDefault()
        setAnchorEl((existing) => ({
            ...existing,
            [id]: event.currentTarget,
        }))
    }
    const handleClose = (id) => () => {
        setAnchorEl(() => ({
            [id]: null,
        }))
    }
    const navigateTo = (id, event, page, pathPrefix) => {
        event.preventDefault()
        setAnchorEl((existing) => ({
            ...existing,
            [id]: null,
        }))
        const handleIndex = (page) =>
            page === 'index'
                ? '/'
                : pathPrefix
                ? `/${pathPrefix}/${page}`
                : `/${page}`
        const finalUrl = handleIndex(page)
        navigate(finalUrl)
    }
    const recursiveMapper =
        (arr, level = 0) =>
        (item, index) => {
            const childrenOfItem = findMyChildren(arr, item.node.id)
            const menuId = `menu-${level}-${item.node.menuText}`
            if (childrenOfItem.length > 0) {
                if (level === 0) {
                    return (
                        // <Grid
                        //     item
                        //     sm={3}
                        //     key={item.node.id}
                        //     className={classes.gridItem}
                        // >
                        //     <Button
                        //         endIcon={<ArrowDropDownIcon />}
                        //         className={classes.link}
                        //         onClick={handleClick(menuId)}
                        //     >
                        //         {item.node.menuText}
                        //     </Button>
                        //     <Menu
                        //         style={{ marginTop: '3rem', minWidth: '20rem' }}
                        //         id={menuId}
                        //         anchorEl={anchorEl[menuId]}
                        //         keepMounted
                        //         open={Boolean(anchorEl[menuId])}
                        //         onClose={handleClose(menuId)}
                        //         TransitionComponent={Fade}
                        //     >
                        //         {childrenOfItem.map(
                        //             recursiveMapper(arr, level + 1)
                        //         )}
                        //     </Menu>
                        // </Grid>
                        <Box
                            style={{ display: 'flex' }}
                            key={item.node.id}
                            className={classes.gridItem}
                        >
                            <Button
                                endIcon={<ArrowDropDownIcon />}
                                className={classes.link}
                                onClick={handleClick(menuId)}
                            >
                                {item.node.menuText}
                            </Button>
                            <Menu
                                style={{ marginTop: '3rem', minWidth: '20rem' }}
                                id={menuId}
                                anchorEl={anchorEl[menuId]}
                                keepMounted
                                open={Boolean(anchorEl[menuId])}
                                onClose={handleClose(menuId)}
                                TransitionComponent={Fade}
                            >
                                {childrenOfItem.map(
                                    recursiveMapper(arr, level + 1)
                                )}
                            </Menu>
                        </Box>
                    )
                }
                return (
                    <Grid key={item.node.id}>
                        <Button
                            style={{
                                fontWeight: 'normal',
                                fontSize: '1.1875rem',
                                textAlign: 'left',
                                paddingLeft: '16px',
                            }}
                            endIcon={<ArrowRightIcon />}
                            className={classes.link}
                            onClick={handleClick(menuId)}
                        >
                            {item.node.menuText}
                        </Button>
                        <Menu
                            style={{ marginLeft: '15.5rem' }}
                            id={menuId}
                            key={item.node.id}
                            anchorEl={anchorEl[menuId]}
                            keepMounted
                            open={Boolean(anchorEl[menuId])}
                            onClose={handleClose(menuId)}
                            TransitionComponent={Fade}
                        >
                            {childrenOfItem.map(
                                recursiveMapper(arr, level + 1)
                            )}
                        </Menu>
                    </Grid>
                )
            }
            //in case there is no dropdown
            if (level === 0) {
                return (
                    // <Grid
                    //     item
                    //     sm={3}
                    //     key={item.node.id}
                    //     className={classes.gridItem}
                    // >
                    // <Button
                    //     className={classes.link}
                    //     onClick={(event) =>
                    //         navigateTo(
                    //             menuId,
                    //             event,
                    //             item.node.name,
                    //             item.node.pathPrefix
                    //         )
                    //     }
                    // >
                    //     {item.node.menuText}
                    // </Button>
                    // </Grid>
                    <Box
                        style={{ display: 'flex' }}
                        key={item.node.id}
                        className={classes.gridItem}
                    >
                        <Button
                            className={classes.link}
                            onClick={(event) =>
                                navigateTo(
                                    menuId,
                                    event,
                                    item.node.name,
                                    item.node.pathPrefix
                                )
                            }
                        >
                            {item.node.menuText}
                        </Button>
                    </Box>
                )
            }
            return (
                <MenuItem
                    className={classes.link}
                    style={{
                        fontWeight: 'normal',
                        fontSize: '1.1875rem',
                        textAlign: 'left',
                    }}
                    key={item.node.id}
                    onClick={(event) =>
                        navigateTo(
                            menuId,
                            event,
                            item.node.name,
                            item.node.pathPrefix
                        )
                    }
                >
                    {item.node.menuText}
                </MenuItem>
            )
        }
    return (
        <React.Fragment>{roots.map(recursiveMapper(menuData))}</React.Fragment>
    )
}
const TopBar = (props) => {
    const data = props.data[props.componentId]
    const menuData = props.extraData.data
    const classes = useStyles()
    // const available = option => option.available;
    const hasMenuItems = Boolean(data.showMenuItems)

    const dataOptionz = {
        0: {
            available: true,
            contentful_id: '3PunlTsT2l07jGVj4Z4Tky',
            id: '3PunlTsT2l07jGVj4Z4Tky',
            menuText: 'Home',
            name: 'index',
            order: 1,
            title: 'Melio Education - Online Learning with Oxford and Cambridge tutors',
        },
        1: {
            available: true,
            contentful_id: '480jXQp2ZpVYOS0AunDTEE',
            id: '480jXQp2ZpVYOS0AunDTEE',
            menuText: 'University preparation',
            name: 'university-preparation',
            order: 2,
            title: 'University Preparation (Root menu)',
        },
        2: {
            available: true,
            contentful_id: '4tbQ50DrlyOpEqHTYWZO8V',
            id: '4tbQ50DrlyOpEqHTYWZO8V',
            menuText: 'Where to study',
            name: 'where-to-study',
            order: 3,
            title: 'Where to study (Root menu)',
        },
        3: {
            available: true,
            contentful_id: '2mxEinTMBYE4OtKlBgLp8u',
            id: '2mxEinTMBYE4OtKlBgLp8u',
            menuText: 'Get advice',
            name: 'blog',
            order: 4,
            title: 'Blog',
        },
        // 4: {
        //     available: true,
        //     contentful_id: '3pk6v4cfcMxChmlAmqsRvr',
        //     id: '3pk6v4cfcMxChmlAmqsRvr',
        //     menuText: 'About',
        //     name: 'about',
        //     order: 5,
        //     title: 'About Melio',
        // },
        5: {
            available: true,
            contentful_id: '5LWO5JBWJI13L6RFPkiuHI',
            id: '5LWO5JBWJI13L6RFPkiuHI',
            menuText: 'Schools',
            name: 'schools',
            order: 6,
            title: 'schools',
        },
    }
    // const filteredOptions = data.options.filter(available);
    const filteredOptions = dataOptionz
    // console.log('filteredOptions: ', filteredOptions)
    const images = getImage(data.logo)
    return (
        <AppBar className={classes.appBar}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    {/* <Grid container lg={12}>
                        <Grid
                            item
                            xs={6}
                            sm={2}
                            className={classes.navPosition}
                        >
                            <Box pt={0.5}>
                                <Link to="/">
                                    <GatsbyImage
                                        className={classes.image}
                                        image={images}
                                        alt={data.logo.title}
                                    />
                                </Link>
                            </Box>
                        </Grid>
                        {hasMenuItems && (
                            <>
                                <Hidden smDown>
                                    <Grid container item sm={8}>
                                        <MenuRenderer
                                            options={filteredOptions}
                                            menuData={menuData}
                                            classes={classes}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        sm={2}
                                        className={classes.buttonContainer}
                                        style={{ marginLeft: '-40px' }}
                                    >
                                        {data &&
                                            data.button &&
                                            data.button.map((button) => {
                                                const buttonColor = (color) => {
                                                    if (color === 'Orange') {
                                                        return classes.buttonPrimary
                                                    }
                                                    return classes.buttonSecondary
                                                }
                                                const theButtonColor =
                                                    buttonColor(
                                                        button.buttonColor
                                                    )
                                                return (
                                                    <Grid item xs={12}>
                                                        <ButtonSelector
                                                            className={
                                                                theButtonColor
                                                            }
                                                            data={button}
                                                        >
                                                            asdf
                                                        </ButtonSelector>
                                                    </Grid>
                                                )
                                            })}
                                    </Grid>
                                </Hidden>

                                <Hidden mdUp>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={8}
                                        className={classes.buttonContainer}
                                    >
                                        <Box pt={2}>
                                            <TemporaryDrawer
                                                {...props}
                                                menuData={menuData}
                                                data={data}
                                                filteredOptions={
                                                    filteredOptions
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                </Hidden>
                            </>
                        )}
                    </Grid> */}
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Box pt={0.5}>
                            <Link to="/">
                                <GatsbyImage
                                    className={classes.image}
                                    image={images}
                                    alt={data.logo.title}
                                />
                            </Link>
                        </Box>
                        {hasMenuItems && (
                            <>
                                <Hidden smDown>
                                    <Box
                                        px={2}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <MenuRenderer
                                            options={filteredOptions}
                                            menuData={menuData}
                                            classes={classes}
                                        />
                                    </Box>
                                    <Box className={classes.buttonContainer}>
                                        {data &&
                                            data.button &&
                                            data.button.map((button) => {
                                                const buttonColor = (color) => {
                                                    if (color === 'Orange') {
                                                        return classes.buttonPrimary
                                                    }
                                                    return classes.buttonSecondary
                                                }
                                                const theButtonColor =
                                                    buttonColor(
                                                        button.buttonColor
                                                    )
                                                const hasTagClass =
                                                    button.tagClass
                                                        ? button.tagClass
                                                        : ''
                                                return (
                                                    <Box>
                                                        <ButtonSelector
                                                            className={` ${theButtonColor} ${hasTagClass}`}
                                                            data={button}
                                                        >
                                                            asdf
                                                        </ButtonSelector>
                                                    </Box>
                                                )
                                            })}
                                    </Box>
                                </Hidden>
                                <Hidden mdUp>
                                    <Box
                                        pt={2}
                                        style={{
                                            marginLeft: '200px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <TemporaryDrawer
                                            {...props}
                                            menuData={menuData}
                                            data={data}
                                            filteredOptions={filteredOptions}
                                        />
                                    </Box>
                                </Hidden>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default TopBar
